<template>
  <div>
    <v-btn
      color="primary"
      block
      @click="onAddSubmenu"
    >
      {{ $t('dtouch.addSubmenu', locale) }}
    </v-btn>
    <v-row style="padding-top: 10px;">
      <v-col xs="5" style="padding-bottom:0">
        <v-divider style="margin-top: 13px;"/>
      </v-col>
      <v-col xs="2" style="padding-bottom:0">
        <center>
          <b>{{ $t('common.or', locale) }}</b>
        </center>
      </v-col>
      <v-col xs="5" style="padding-bottom:0">
        <v-divider style="margin-top: 13px;"/>
      </v-col>
    </v-row>
    <v-layout wrap style="padding-bottom: 10px">
      <v-flex xs7 style="padding:0;" />
      <v-flex xs5 style="padding: 0 10px 0 20px">
        <v-text-field
          v-model="contentTypeFilter"
          :label="$t('common.filter',locale)"
          append-outer-icon="mdi-filter"
          hide-details
          dense
        />
      </v-flex>
    </v-layout>
    <v-list>
      <v-list-item-group v-model="selectedContentType"
        x-small
      >
        <v-list-item
          v-for="(item) in contentTypesFiltered"
          :key="item.id"
          dense
          :value="item.id"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon" />
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title v-text="$t('contentType.'.concat(item.name), locale)" />
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <!--v-row>
      <v-col xs="3"
        v-for="item of contentTypes"
        :key="item.id"
      >
        <center>
          <v-btn
            class="ma-2"
            outlined
            fab
            color="primary"
            large
          >
            <v-icon>{{item.icon}}</v-icon>
          </v-btn>
        </center>
        <center>
          {{$t('contentType.'.concat(item.name), locale)}}
        </center>
      </v-col>
    </v-row-->
    <v-btn
      @click="onClose"
      block
    >
    {{ $t('common.cancel', locale) }}
    </v-btn>
  </div>
</template>
<script>
import api from '@/services/api'
import utils from '@/services/utils'
export default {
  props: {
    locale: {
      type: String,
      default: null,
    },
    onAddSubmenu: {
      type: Function,
      required: true,
    },
    onSelectContentType: {
      type: Function,
      required: true,
    },
    onClose: {
      type: Function,
      required: true,
    },
    newParentID: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    contentTypes: null,
    contentTypesFiltered: null,
    selectedContentType: null,
    contentTypeFilter: null,
  }),
  watch: {
    newParentID () {
      this.prepareData()
    },
    selectedContentType (v) {
      if(v !== null) this.onSelectContentType(v)
    },
    contentTypeFilter () {
      this.handleFilter()
    },
  },
  mounted () {
    this.prepareData()
  },
  methods: {
    prepareData () {
      const workspaceID = utils.getWorkspace('dtouch')
      api.getItem ('dtouch', 'v1/private/workspaces/', workspaceID.concat('/content-types'))
        .then(response => {
          this.contentTypes = response.filter(x => x.id !== 1 && x.migrated)
          this.handleFilter()
        })
    },
    handleFilter () {
      this.contentTypesFiltered = this.contentTypes.filter(x => !this.contentTypeFilter || this.contentTypeFilter.length === 0 || x.name.toUpperCase().indexOf(this.contentTypeFilter.toUpperCase()) >= 0 || this.$t('contentType.'.concat(x.name), this.locale).toUpperCase().indexOf(this.contentTypeFilter.toUpperCase()) >= 0)
    },
  }
}
</script>

